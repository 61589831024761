import logger from 'logger'
import localStorage from 'local-storage'
import { constants } from 'helpers'

import { getAnalyticsContext } from './context'
import mixpanel from './mixpanel'
import rudderstack from './rudderstack'


const pageEventSessionTime = 30 * 60 * 1000 // 30 minutes

const isPageEventSessionActive = () => {
  const nowTime = Date.now()
  const lastPageEventTime = localStorage.getItem<number>(constants.localStorageNames.lastAnalyticsPageEventTime)
  const timeDiff = lastPageEventTime ? nowTime - lastPageEventTime : 0

  return !lastPageEventTime || timeDiff > pageEventSessionTime
}

const updatePageEventSessionActivity = () => {
  localStorage.setItem(constants.localStorageNames.lastAnalyticsPageEventTime, Date.now())
}

export const track: Analytics.TrackFunction<Analytics.Events> = (...args) => {
  let [ eventName, params ] = args
  const isPageEvent = /\spage$/.test(eventName)

  if (isPageEvent) {
    const isEntryPoint = isPageEventSessionActive()

    if (isEntryPoint) {
      params = {
        ...params,
        isEntryPoint: true,
      }
    }

    updatePageEventSessionActivity()
  }

  if (__DEV__) {
    const { page, pageType } = getAnalyticsContext()

    logger.info({
      eventName,
      properties: {
        page,
        pageType,
        ...params,
      },
    }, `[Analytics] track`)
  }

  // @ts-expect-error
  mixpanel.track(eventName, params)
  // @ts-expect-error
  rudderstack.track(eventName, params)
}

const trackedEvents = new Set<string>()

// track event only one per session
export const trackOnce: Analytics.TrackFunction<Analytics.Events> = (...args) => {
  const [ eventName ] = args

  if (trackedEvents.has(eventName)) {
    return
  }

  trackedEvents.add(eventName)
  // @ts-expect-error
  track(...args)
}

export * from './context'
export { default as dataLayer } from './dataLayer'
export { default as attentive } from './attentive'
export { default as noibu } from './noibu'
export {
  mixpanel,
  rudderstack,
}
