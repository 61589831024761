import React, { cloneElement, isValidElement, Children } from 'react'
import { useOnRouteMatch, usePathname } from './hooks'
import { createMatcher } from './utils'
import type { Match } from './types'


const matcher = createMatcher()

export type SwitchProps = {
  children: React.ReactNode
}

export const Switch: React.FunctionComponent<SwitchProps> = (props) => {
  const { children } = props
  const pathname = usePathname()
  const onChange = useOnRouteMatch()

  const nodesToRender = []
  let match: Match

  const matchRoutes = (children: React.ReactNode) => {
    Children.toArray(children).some((child) => {
      if (match) {
        return true
      }

      if (isValidElement(child)) {
        if (child.props.path) {
          if (child.props.path === '*') {
            match = { path: '*', params: {} }
            nodesToRender.push(child)
            return
          }

          const result = matcher(child.props.path, pathname)

          // if pathname match route path then add Route to nodesToRender
          if (result) {
            match = result
            nodesToRender.push(child)
          }
        }
        else {
          // if node is Layout then add it to nodesToRender
          nodesToRender.push(child)

          // and iterate again
          matchRoutes(child.props.children)

          // if in nested routes no match then remove added Layout (move up by tree)
          if (!match) {
            nodesToRender.pop()
          }
        }
      }
    })
  }

  matchRoutes(children)

  if (match) {
    if (typeof onChange === 'function') {
      onChange(match)
    }

    nodesToRender.reverse()

    return nodesToRender.reduce((result, node) => {
      // render route
      if (!result) {
        return cloneElement(node, { match })
      }

      // render layout
      return cloneElement(node, { match }, result)
    }, null)
  }

  return null
}
