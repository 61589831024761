import logger from 'logger'
import analyticsWrapper from '../wrapper'


declare global {
  interface Window {
    NOIBUJS?: {
      addCustomAttribute: (name: string, value: string) => void
    }
  }
}

type Methods = {
  addCustomAttribute: (name: string, value: string) => void
}

const noibu = analyticsWrapper<Methods>({
  entityKey: 'NOIBUJS',
  resolveMethods: {
    addCustomAttribute: (name, value) => {
      logger.info({ value }, '[Noibu] add attribute %s', name)

      return window.NOIBUJS.addCustomAttribute(name, value)
    },
  },
})


export default noibu
