import React, { startTransition, useEffect, useMemo, useRef, useState } from 'react'
import type { LocationState, NavigateMethod, NavigateOptions, OnRouteMatch, SearchParamsResult } from '../shared/types'
import { BaseRouter } from '../shared/BaseRouter'
import { createPath, getHref, getSearchParams, mergeSearchParams } from '../shared/utils'


// While History API does have `popstate` event, the only
// proper way to listen to changes via `push/replaceState`
// is to monkey-patch these methods.
//
// See https://stackoverflow.com/a/4585031
let patched

const patchHistoryEvents = () => {
  if (patched) {
    return
  }

  [ 'pushState', 'replaceState' ].forEach((type) => {
    const original = history[type]

    history[type] = function () {
      const result = original.apply(history, arguments)
      const event = new Event(type)
      // @ts-expect-error
      event.arguments = arguments

      window.dispatchEvent(event)
      return result
    }
  })

  patched = true
}

const navigate: NavigateMethod = (to, { searchParams, title, state, replace, scroll = true }: NavigateOptions) => {
  let href = getHref(to)
  if (searchParams) {
    href = mergeSearchParams(href, searchParams)
  }

  if (!href.startsWith('/')) {
    window.location[replace ? 'replace' : 'assign'](href)
  }
  else {
    window.history[replace ? 'replaceState' : 'pushState'](state || null, title || null, href)
  }

  if (scroll) {
    // Move to macro-task to fix scrolling for mobile
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 10)
  }
}

export type BrowserRouterProps = {
  children: React.ReactNode
  onRouteMatch?: OnRouteMatch
}

export const BrowserRouter: React.FC<BrowserRouterProps> = (props) => {
  const { children, onRouteMatch } = props

  const [ pathname, setPathname ] = useState<string>(window.location.pathname)
  const [ searchParams, setSearchParams ] = useState<SearchParamsResult>(() => getSearchParams(window.location.search))

  const lastPath = useRef(window.location.pathname)
  const lastSearch = useRef(window.location.search)
  const prevPath = useRef<string>(null)
  const prevSearch = useRef<string>(null)

  useEffect(() => {
    patchHistoryEvents()

    // this function checks if the location has been changed since the
    // last render and updates the state only when needed.
    // unfortunately, we can't rely on `path` value here, since it can be stale,
    // that's why we store the last pathname in a ref.
    const checkForUpdates = () => {
      // save last path as previous path
      prevPath.current = lastPath.current
      prevSearch.current = lastSearch.current

      const { pathname, search } = window.location

      if (lastPath.current !== pathname || lastSearch.current !== search) {
        startTransition(() => {
          if (lastPath.current !== pathname) {
            lastPath.current = pathname
            setPathname(pathname)
          }

          if (lastSearch.current !== search) {
            lastSearch.current = search
            setSearchParams(getSearchParams(search))
          }
        })
      }
    }

    const events = [ 'popstate', 'pushState', 'replaceState' ]
    events.forEach((event) => window.addEventListener(event, checkForUpdates))

    // it's possible that an setPath has occurred between render and the effect handler,
    // so we run additional check on mount to catch these updates. Based on:
    // https://gist.github.com/bvaughn/e25397f70e8c65b0ae0d7c90b731b189
    checkForUpdates()

    return () => {
      events.forEach((event) => window.removeEventListener(event, checkForUpdates))
    }
  }, [])

  const location = useMemo((): LocationState => {
    const previousUrl = createPath({ pathname: prevPath.current, search: prevSearch.current })
    const currentUrl = createPath({ pathname: lastPath.current, search: lastSearch.current })

    return {
      pathname,
      search: lastSearch.current,
      searchParams,
      currentUrl,
      previousUrl,
    }
  }, [
    pathname,
    searchParams,
  ])

  return (
    <BaseRouter location={location} navigate={navigate} onRouteMatch={onRouteMatch}>
      {children}
    </BaseRouter>
  )
}
