import cookieStorage from 'cookie-storage'
import logger from 'logger'
import createEventsSaver from './eventsSaver'


type Options<Methods> = {
  entityKey: string
  entityResolver?: () => boolean
  resolveMethods: {
    [K in keyof Methods]: boolean | Methods[K]
  }
  /**
   * Timeout in milliseconds to wait for entity to resolve
   * @default 20000 (20 seconds)
   */
  resolveTimeout?: number
}

const areEventsDisabled = __SERVER__ || cookieStorage.getItem('noAnalytics')

/**
 * Creates a wrapper for analytics methods that handles delayed initialization
 * The wrapper ensures analytics events are properly tracked even when the
 * analytics entity isn't immediately available. It can queue events until
 * the entity is resolved
 *
 * @param options Configuration options for the wrapper
 * @returns A proxy object with the same methods as the original analytics entity
 */
const analyticsWrapper = <Methods extends object>(options: Options<Methods>): Methods => {
  if (__SERVER__) {
    return
  }

  const { entityKey, entityResolver, resolveMethods } = options

  const eventsSaver = createEventsSaver(entityKey)
  const resolver = entityResolver || (() => Boolean(window[entityKey]))

  let isEntityResolved = resolver()
  let entityResolveTimer = null
  const waitList = []

  if (!isEntityResolved) {
    entityResolveTimer = setInterval(() => {
      const isEntityResolvedNow = resolver()

      if (isEntityResolvedNow) {
        clearInterval(entityResolveTimer)
        entityResolveTimer = null
        isEntityResolved = true

        while (waitList.length > 0) {
          const resolveMethod = waitList.shift()
          resolveMethod()
        }
      }
    }, 250)

    setTimeout(() => {
      if (entityResolveTimer) {
        clearInterval(entityResolveTimer)
        entityResolveTimer = null
        waitList.length = 0

        logger.warn(`%s initialization failed`, entityKey)
      }
    }, options.resolveTimeout || 20000)
  }

  const executeMethod = (methodName: string, args: any[]) => {
    if (resolveMethods[methodName] === true) {
      return window[entityKey][methodName](...args)
    }
    if (typeof resolveMethods[methodName] === 'function') {
      return resolveMethods[methodName](...args)
    }
  }

  return Object.keys(resolveMethods).reduce((acc, methodName) => {
    acc[methodName] = (...args: any[]) => {
      eventsSaver({
        methodName,
        data: args,
      })

      if (areEventsDisabled) {
        return
      }

      try {
        if (isEntityResolved) {
          return executeMethod(methodName, args)
        }

        if (entityResolveTimer) {
          waitList.push(() => executeMethod(methodName, args))
        }
      }
      catch (error) {
        logger.error(error)
      }
    }

    return acc
  }, {}) as Methods
}


export default analyticsWrapper
