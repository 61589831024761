import { createContext } from 'react'
import type { LocationState, NavigateMethod, OnRouteMatch, Params, SearchParamsContextValue } from './types'


export const LocationStateContext = createContext<LocationState>(null)
export const NavigateContext = createContext<NavigateMethod>(null)
export const PathnameContext = createContext<string>(null)
export const SearchContext = createContext<string>(null)
export const SearchParamsContext = createContext<SearchParamsContextValue>(null)
export const RouteParamsContext = createContext<Params>(null)
// notify when matched route is found or changes
export const OnRouteMatchContext = createContext<OnRouteMatch | null>(null)
