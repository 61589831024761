import logger from 'logger'
import analyticsWrapper from '../wrapper'
import { getAnalyticsContext } from '../context'


type Properties = Record<string, string | number | boolean | any>

declare global {
  interface Window {
    rudderanalytics: {
      getAnonymousId: () => string
      identify: (userId: string, traits: Properties) => void
      page: (category: string, name: string, properties: Properties) => void
      track: (eventName: string, properties: Properties) => void
      reset: (withAnonymousId?: boolean) => void
      isReady?: boolean
    }
  }
}

type Events = Analytics.Events

type Methods = {
  getAnonymousId: () => string
  identify: (userId: string, traits: Record<string, string | number>) => void
  reset: () => void
  page: (category: string, name: string, properties: Properties) => void
  track: Analytics.TrackFunction<Events>
}


const rudderstack = analyticsWrapper<Methods>({
  entityKey: 'rudderanalytics',
  resolveTimeout: 30000,
  entityResolver: () => window?.rudderanalytics?.isReady === true,
  resolveMethods: {
    identify: true,
    getAnonymousId: true,
    reset: true,
    page: (category: string, name: string, properties: Properties = undefined) => {
      const finalProperties = {
        ...getAnalyticsContext(),
        ...properties,
      }

      logger.info({ category, name, properties: finalProperties }, `[Rudderstack] page`)

      window.rudderanalytics.page(category, name, { payload: finalProperties })
    },
    track: (eventName, properties = undefined) => {
      const finalProperties = {
        ...getAnalyticsContext(),
        ...properties,
      }

      logger.info({ eventName, properties: finalProperties }, `[Rudderstack] track`)

      window.rudderanalytics.track(eventName, { payload: finalProperties })
    },
  },
})


export default rudderstack
