import { useContext, useMemo } from 'react'
import type { NavigateOptions, To, SearchParams, SetSearchParamsFn } from './types'
import { LocationStateContext, NavigateContext, PathnameContext, SearchContext, SearchParamsContext, RouteParamsContext, OnRouteMatchContext } from './contexts'


export const useNavigate = () => useContext(NavigateContext)

// for legacy code
export const useHistory = () => {
  const navigate = useContext(NavigateContext)
  return useMemo(() => ({
    push: (to: To, options?: Omit<NavigateOptions, 'replace'>) => navigate(to, { ...options, replace: false }),
    replace: (to: To, options?: Omit<NavigateOptions, 'replace'>) => navigate(to, { ...options, replace: true }),
  }), [ navigate ])
}

export const useLocationState = () => useContext(LocationStateContext)
export const usePathname = () => useContext(PathnameContext)
export const useSearch = () => useContext(SearchContext)
export const useSearchParams = <T = SearchParams>() => useContext(SearchParamsContext) as [ T, SetSearchParamsFn ]
export const useParams = () => useContext(RouteParamsContext)

// useLocation
// useNavigate //
// useHref // converts `to` to href
// useParams // returns dynamic params
// useSearchParams // controls search params

// provides a callback which is called when route is matched
export const useOnRouteMatch = () => useContext(OnRouteMatchContext)
